<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-table ref="refReturnListTable"
            class="position-relative b-table-responsive mb-0 text-small small" :items="returns"
            responsive selectable select-mode="single" :fields="columnReturns" primary-key="articulo_Id"
            show-empty empty-text="No se encontraron resultados" :busy.sync="loading" stacked="md"
            small>

            <!-- Empty -->
            <template slot="empty">
                <h6 class="text-center mt-1">
                    No se encontraron registros
                </h6>
            </template>

                <!-- Fecha Salida -->
                <template #cell(fecha_Salida)="data">
                {{ formatDate(data.item.fecha_Salida) }}
            </template>
        </b-table>
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import EventBus from "@/event-bus"
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { VueGoodTable } from 'vue-good-table'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import { mapGetters, mapActions } from 'vuex';
import ArticlesInvoice from '@/@core/components/invoices/ArticlesInvoice.vue';
import AuthorizationModal from '@/@core/components/AuthorizationModal.vue'
import SearchByCustomer from "@core/components/invoices/SearchByCustomer.vue"

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

import DoctosPVProvider from "@/providers/DoctosPV"
import FeatherIcon from '../feather-icon/FeatherIcon.vue';
const DoctosPVResource = new DoctosPVProvider()

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import moment from 'moment';
import { nextTick } from '@vue/composition-api';
const DeliveryRoutesResource = new DeliveryRoutesProvider();

export default {
    name: 'SearchInvoices',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        VueGoodTable,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        FeatherIcon,
        BTabs,
        BTab,

        //
        ArticlesInvoice,
        AuthorizationModal,
        SearchByCustomer,
        vSelect,
    },
    mixins: [heightTransition],
    props: {
       returns: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    key: 'um_Total',
                    label: 'Um Total',
                    tdClass: 'text-center',
                },
                {
                    key: 'articulo',
                    label: 'Articulo',
                },
                {
                    key: 'um',
                    label: 'Um',
                    tdClass: 'text-center',
                },
                {
                    key: 'entregado',
                    label: 'Entregado',
                    tdClass: 'text-center',
                },
                {
                    key: 'cargado',
                    label: 'Cargado',
                },
                {
                    key: 'pendiente',
                    label: 'Pendiente',
                    tdClass: 'text-center',
                },
                // {
                //     key: 'estatus_Retorno',
                //     label: 'Estatus',
                // },
            ],
            columnReturns: [
                { key: 'nombre_Usuario', label: 'Creado Por' },
                { key: 'articulo_Nombre', label: 'Articulo' },
                { key: 'unidades', label: 'Unidad' },
                { key: 'fecha_Salida', label: 'Fecha Salida', tdClass: 'text-capitalize' },
                //{ key: 'fecha_Retorno', label: 'Retorno' },
                { key: 'nombre_Chofer', label: 'Chofer' },
                { key: 'nombre_Chofer', label: 'Verifico' },
            ],
        }
    },
    created () {},
    computed: {},
    mounted() {},
    beforeDestroy() {},
    methods: {},
    watch: {}    
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>